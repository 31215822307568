import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyB8Ol2TxhXBZnj0unpBd_g2WDVipnj16wo",
  authDomain: "anjanidc-b0e8a.firebaseapp.com",
  projectId: "anjanidc-b0e8a",
  storageBucket: "anjanidc-b0e8a.appspot.com",
  messagingSenderId: "456618777168",
  appId: "1:456618777168:web:a4006e035a96bbafc0cf55"
};

const app = initializeApp(firebaseConfig);
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider("6Ld_2l4oAAAAAKe2CSS1q9hKNMMSytDzkxWoeow1"),
//   isTokenAutoRefreshEnabled: true,
// });

export const auth = getAuth(app);
export const db = getFirestore(app);
