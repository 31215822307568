import React, { useEffect, useState } from "react";
import ItemDataService from "../../services/item.service";
import img from "../images/logo.png";

const Map = () => {
  const [items, setItems] = useState([]);

  const borders =[{"X":3431,"Y":3010},{"X":3432,"Y":3256},{"X":475,"Y":3256},{"X":458,"Y":3456},{"X":0,"Y":0},{"X":212,"Y":3456},{"X":228,"Y":3255},{"X":10,"Y":3254},{"X":0,"Y":0},{"X":10,"Y":3008},{"X":517,"Y":3008},{"X":0,"Y":0},{"X":3412,"Y":2456},{"X":3405,"Y":2272},{"X":0,"Y":0},{"X":3385,"Y":1719},{"X":3378,"Y":1535},{"X":0,"Y":0},{"X":3358,"Y":982},{"X":3352,"Y":797},{"X":0,"Y":0},{"X":3336,"Y":315},{"X":3198,"Y":314},{"X":0,"Y":0},{"X":2055,"Y":355},{"X":1871,"Y":362},{"X":0,"Y":0},{"X":775,"Y":798},{"X":679,"Y":982},{"X":0,"Y":0},{"X":613,"Y":1536},{"X":599,"Y":1719},{"X":0,"Y":0},{"X":554,"Y":2272},{"X":539,"Y":2457},{"X":0,"Y":0}]

  const roads = [{"X":1085,"Y":3137,"TXT":40,"D":0},{"X":1962,"Y":3137,"TXT":40,"D":0},{"X":2930,"Y":3135,"TXT":40,"D":0},{"X":1185,"Y":2361,"TXT":30,"D":0},{"X":2649,"Y":2358,"TXT":30,"D":0},{"X":1204,"Y":1623,"TXT":30,"D":0},{"X":2733,"Y":1625,"TXT":30,"D":0},{"X":1250,"Y":888,"TXT":30,"D":0},{"X":2745,"Y":892,"TXT":30,"D":0},{"X":1971,"Y":1616,"TXT":30,"D":90},{"X":1972,"Y":2430,"TXT":30,"D":90},{"X":1972,"Y":787,"TXT":30,"D":90}]

  const misc = [{"Ax":518,"Ay":3009,"Bx":495,"By":3009,"Cx":517,"Cy":2733,"Dx":518,"Dy":3009,"Text":"Fill","Color":"#DAF7A6"},{"Ax":540,"Ay":2733,"Bx":517,"By":2733,"Cx":539,"Cy":2456,"Dx":540,"Dy":2733,"Text":"Fill","Color":"#DAF7A6"},{"Ax":577,"Ay":2272,"Bx":554,"By":2272,"Cx":577,"Cy":1996,"Dx":577,"Dy":2272,"Text":"Fill","Color":"#DAF7A6"},{"Ax":599,"Ay":1996,"Bx":577,"By":1996,"Cx":599,"Cy":1719,"Dx":599,"Dy":1996,"Text":"Fill","Color":"#DAF7A6"},{"Ax":636,"Ay":1535,"Bx":614,"By":1535,"Cx":636,"Cy":1259,"Dx":636,"Dy":1535,"Text":"Fill","Color":"#DAF7A6"},{"Ax":680,"Ay":1258,"Bx":636,"By":1258,"Cx":654,"Cy":1032,"Dx":679,"Dy":983,"Text":"Fill","Color":"#DAF7A6"},{"Ax":882,"Ay":798,"Bx":775,"By":798,"Cx":794,"Cy":759,"Dx":882,"Dy":671,"Text":"Fill","Color":"#DAF7A6"},{"Ax":1036,"Ay":671,"Bx":882,"By":671,"Cx":1035,"Cy":516,"Dx":1036,"Dy":671,"Text":"Fill","Color":"#DAF7A6"},{"Ax":1343,"Ay":457,"Bx":1189,"By":457,"Cx":1343,"Cy":400,"Dx":1343,"Dy":457,"Text":"Fill","Color":"#DAF7A6"},{"Ax":1495,"Ay":400,"Bx":1344,"By":400,"Cx":1400,"Cy":379,"Dx":1497,"Dy":376,"Text":"Fill","Color":"#DAF7A6"},{"Ax":1189,"Ay":515,"Bx":1036,"By":515,"Cx":1189,"Cy":457,"Dx":1189,"Dy":515,"Text":"Fill","Color":"#DAF7A6"}]

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const data = await ItemDataService.getAllItems();
    var canvas = document.querySelector("canvas");
    canvas.width = 3800;
    canvas.height = 9000;
    var c = canvas.getContext("2d");
    c.lineWidth = 5;

    var d = new Date();
    d.setDate(d.getDate() - 11);

    data.docs.map((doc) => {
      var plot = doc.data();
      const vertices = [
        { x: plot.Ax, y: plot.Ay },
        { x: plot.Bx, y: plot.By },
        { x: plot.Cx, y: plot.Cy },
        { x: plot.Dx, y: plot.Dy },
      ];

      if (plot.Status === "Allotted") {
        c.fillStyle = "#82E0AA";
      } else if (
        plot.Status === "Booked" &&
        plot.bookedDate &&
        plot.bookedDate.toDate() > d
      ) {
        c.fillStyle = "#FFC300";
      } else {
        c.fillStyle = "white";
      }
      c.beginPath();
      c.moveTo(vertices[0].x, vertices[0].y);
      for (let i = 1; i < vertices.length; i++) {
        c.lineTo(vertices[i].x, vertices[i].y);
      }
      c.lineTo(vertices[0].x, vertices[0].y);
      c.stroke();
      c.closePath();
      c.fill();
      const centerX = (vertices[0].x + vertices[2].x) / 2;
      const centerY = (vertices[0].y + vertices[2].y) / 2;
      const rotationAngle = 15 * (Math.PI / 180);
      c.translate(centerX, centerY);
      c.rotate(-rotationAngle);

      const text = plot.No;
      c.font = "30px Arial";
      c.fillStyle = "black";
      c.textAlign = "center";

      c.fillText(text, 0, 0);

      c.rotate(rotationAngle);
      c.translate(-centerX, -centerY);
    });

    var begin = true;
    c.lineWidth = 3;
    borders.forEach((line) => {
      if (begin) {
        c.beginPath();
        c.moveTo(line.X, line.Y);
        begin = false;
      } else {
        if (line.X > 0) {
          c.lineTo(line.X, line.Y);
        } else {
          begin = true;
          c.stroke();
          c.closePath();
        }
      }
    });

    roads.forEach((rd) => {
      const rotationAngle = rd.D * (Math.PI / 180);
      c.translate(rd.X, rd.Y);
      let text = "";
      c.rotate(-rotationAngle);
      if (rd.TXT === 31) {
        text = "EXIST WIDE ROAD";
      } else if (rd.TXT === 29) {
        text = "Houses of Ramaraopet Village";
      } else {
        text = rd.TXT + " FEET WIDE ROAD";
      }
      c.font =
        rd.TXT === 100 ? "45px Georgia bold" : rd.TXT + "px Georgia bold";
      c.fillStyle = "black";
      c.textAlign = "center";

      c.fillText(text, 0, 0);
      c.rotate(rotationAngle);
      c.translate(-rd.X, -rd.Y);
    });

    misc.forEach((plot) => {
      const vertices = [
        { x: plot.Ax, y: plot.Ay },
        { x: plot.Bx, y: plot.By },
        { x: plot.Cx, y: plot.Cy },
        { x: plot.Dx, y: plot.Dy },
      ];

      c.fillStyle = plot.Color;

      c.beginPath();
      c.moveTo(vertices[0].x, vertices[0].y);
      for (let i = 1; i < vertices.length; i++) {
        c.lineTo(vertices[i].x, vertices[i].y);
      }
      c.lineTo(vertices[0].x, vertices[0].y);
      c.stroke();
      c.closePath();
      c.fill();
      if (plot.Text !== "Fill") {
        const centerX = (vertices[0].x + vertices[2].x) / 2;
        const centerY = (vertices[0].y + vertices[2].y) / 2;
        const rotationAngle = 15 * (Math.PI / 180);
        c.translate(centerX, centerY);
        c.rotate(-rotationAngle);

        const text = plot.Text;
        c.font = "20px Georgia";
        c.fillStyle = "black";
        c.textAlign = "center";

        c.fillText(text, 0, 0);

        c.rotate(rotationAngle);
        c.translate(-centerX, -centerY);
      }
    });

    canvas.addEventListener("click", function (event) {
      const px = Math.floor(
        event.clientX - canvas.getBoundingClientRect().left,
      );
      const py = Math.floor(event.clientY - canvas.getBoundingClientRect().top);
      console.log(px, py);
      for (let i = 0; i < items.length; i++) {
        if (py > items[i].Ay && py < items[i].Dy) {
          console.log("Clicked on polygon", i + 1);
        }
      }
    });
    let base_image = new Image();
    base_image.src = img;
    base_image.onload = function () {
      c.drawImage(base_image, 1000, 10);
    };
  };
  return (
    <>
      <canvas style={{ width: "100%" }}></canvas>
    </>
  );
};

export default Map;
